<template>
  <div class="p-5 container">
    <div
      class="row mb-5"
      style="padding: 2vh;"
      v-if="listData && listData.length > 0"
    >
      <div
        class="col-12 col-lg-4 mb-5"
        v-for="(item, index) in listData"
        :key="index"
      >
        <router-link :to="'/event/' + item.id">
          <div
            class="card box-item"
            style="border-radius: 10px;border: 1px solid #E9E9EF;min-height: 400px;"
          >
            <img
              :src="imageUrl + item.thumbnailsPath"
              @error="setAltImg"
              alt=""
              class="w-100"
              style="border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            object-fit: cover;
            height: 200px;"
            />
            <div class="card-body" style="min-height: 150px;">
              <p class="text-category" style="color: #9999D3;">
                <!-- {{ item.categoryProfile[0][0].data }} | -->
                {{ formatDate(item.createDate) }}
              </p>
              <div
                class="mb-5 limit-text text-title"
                style="font-weight: 400;
                font-style: normal;
                color: #323563;"
              >
                <b> {{ item.title }}</b>
              </div>
              <div
                class="mb-5 limit-text text-category"
                style="font-weight: 400;
                color: #9F9F9F;"
                v-html="removeStyle(item.description)"
              ></div>
            </div>
            <!-- <div class="card-footer">
            <div style="display: flex;justify-content: center;">
              <router-link :to="'/blogs/' + item.id" class="btn btn-app">
                อ่านเพิ่มเติม
              </router-link>
            </div>
          </div> -->
          </div>
        </router-link>
      </div>
    </div>
    <div class=" row mb-5" v-else>
      <img src="media/image-app/no-data.svg" alt="" style="height:300px" />
    </div>
      <div
      v-if="listData && listData.length > 0"
      style="display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;">
      <b class="text-category" style="color:#9999D3"
        >จำนวน {{ listData && listData.totalElements }} รายการ</b
      >
      <ul class="pagination">
        <li :class="'page-item previous ' + (page == 0 ? 'disabled' : '')">
          <button type="button" @click="backPage()" class="page-link">
            <i class="previous"></i>
          </button>
        </li>
        <div v-for="(item, index) in totalPage" :key="index">
          <li :class="'page-item ' + (index == page ? 'active' : '')">
            <button type="button" @click="changePage(item)" class="page-link">
              {{ item }}
            </button>
          </li>
        </div>
        <li
          :class="'page-item next ' + (page + 1 == totalPage ? 'disabled' : '')"
        >
          <button type="button" @click="nextPage()" class="page-link">
            <i class="next"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
const video = ref();
const canvas = ref();
const captures: any = ref([]);
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(6);
const page = ref(0);
const orderBy = ref(1);
const sortBy = ref(1);
const categories = ref([]);
const totalPage = ref(0);
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "",
});
export default defineComponent({
  name: "event-list",
  components: {},
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("กิจกรรม", []);
      //   , ['ข่าวสารและบทความ']
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      // console.log("getDataList");
      
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getEvent?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            sortBy.value,
          {
            headers: {
              token: localStorage.getItem("id_token") ? localStorage.getItem("id_token") : localStorage.getItem("tokenGuest")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      totalPage,
      filterForm,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
</script>
